import { renameKeysAndReplaceEmpty } from '@/helper/formatter/common';

const keyMap = {
  name: 'name',
  displayName: 'displayName',
};

export function formatUserInfoApiData(userData) {
  return renameKeysAndReplaceEmpty({ inputObj: userData, keyMap });
}
