import { reduce, zipObj } from 'ramda';
import { isEmptyValue, emptyReplace } from '@/helper/data-process';

export function renameKeysAndReplaceEmpty({ inputObj, keyMap, inputObjDefaultValues = {} }) {
  if (isEmptyValue(keyMap)) return {};
  return reduce(
    (output, originalKey) => {
      const newKey = keyMap[originalKey];
      const emptyValue = Object.hasOwn(inputObjDefaultValues, originalKey) ? inputObjDefaultValues[originalKey] : '';
      output[newKey] = emptyReplace(inputObj[originalKey], emptyValue);
      return output;
    },
    {},
    Object.keys(keyMap),
  );
}

export function renameKeysAndReplaceEmptyOfList({ list, keyMap, inputObjDefaultValues = {} }) {
  if (!Array.isArray(list)) return [];
  return list.map((inputObj) => renameKeysAndReplaceEmpty({ inputObj, keyMap, inputObjDefaultValues }));
}

export function reverseKeyValue(inputObj) {
  return zipObj(Object.values(inputObj), Object.keys(inputObj));
}

export function filterByTextField({ list, requiredTextField = 'text' }) {
  return list.filter((x) => x[requiredTextField] && x[requiredTextField].trim());
}
