import * as yup from 'yup';
import {
  checkSizeKb,
  checkImageDimension,
  checkFileType,
  checkUrl,
  checkZhEnNumber,
  checkNoEmoji,
  checkAtListOneTextLink,
  checkColor,
  checkProductSnGuidOrIsbn,
  checkMultiProductSnGuidOrIsbn,
  checkObjectArrayMinLength,
  adImageFileTypeList,
} from '@/validator/rules';
import dayjs from '@/helper/date';
import { isEmptyValue, getRangeValueCountByValue, isEmptyOrEmptyStringAfterTrim } from '@/helper/data-process';
import { validatorLangText } from '@/constants/lang';

const labelText = (prm) => {
  return prm.label ? `${prm.label}` : '';
};

function defineYupArrayMethod() {
  yup.addMethod(yup.array, 'atLeastOneTextLink', function ({ requiredFields = ['text', 'link'] } = {}) {
    return this.test({
      name: 'atLeastOneTextLink',
      message: (prm) => `${labelText(prm)} 至少需填入一個。`,
      test: checkAtListOneTextLink({ requiredFields }),
    });
  });

  yup.addMethod(yup.array, 'minArrayLength', function ({ requiredItemFields, minLength }) {
    return this.test({
      name: 'minArrayLength',
      message: (prm) => `${labelText(prm)} 至少需填入 ${minLength} 個。`,
      test: checkObjectArrayMinLength({ minLength, requiredItemFields }),
    });
  });

  yup.addMethod(yup.array, 'cannotDuplicateSort', function ({ rangeMax }) {
    return this.test({
      name: 'cannotDuplicateSort',
      message: (prm) => `${labelText(prm)}${labelText(prm) && ' '}排序不可重複。`,
      test: (originalList, context) => {
        if (!originalList || !originalList.length) return true;
        const sortCountByValue = getRangeValueCountByValue({
          valueList: originalList.map((x) => x.sort),
          rangeMax,
        });
        const firstDuplicateCount = sortCountByValue.find((x) => x > 1);
        return isEmptyValue(firstDuplicateCount);
      },
    });
  });
}

export function definedYupGlobalMethod() {
  defineYupArrayMethod();

  yup.addMethod(yup.mixed, 'sizeKb', function (sizeKb) {
    return this.test({
      name: 'sizeKb',
      params: { sizeKb },
      message: (prm) => `${labelText(prm)}大小限 ${prm.sizeKb}KB。`,
      test: checkSizeKb(sizeKb),
    });
  });

  yup.addMethod(yup.mixed, 'imageDimension', function ({ width, height }) {
    return this.test({
      name: 'imageDimension',
      params: { width, height },
      message: (prm) => `${labelText(prm)}尺寸僅限 ${prm.width}x${prm.height} px。`,
      test: checkImageDimension({ width, height }),
    });
  });

  yup.addMethod(yup.mixed, 'fileType', function (fileTypeList) {
    return this.test({
      name: 'fileType',
      message: (prm) => validatorLangText.adImageFileType({ label: labelText(prm), imageTypes: fileTypeList }),
      test: checkFileType(fileTypeList),
    });
  });

  yup.addMethod(yup.mixed, 'adImageType', function (imageTypeList = adImageFileTypeList) {
    return this.fileType(imageTypeList);
  });

  yup.addMethod(yup.date, 'isAfterCurrentDatetime', function () {
    return this.test({
      name: 'isAfterCurrentDatetime',
      params: {},
      message: (prm) => `${labelText(prm)} 不能小於當前時間。`,
      test: (value) => {
        if (!value) return true;
        return dayjs(value).isAfter(dayjs());
      },
    });
  });

  yup.addMethod(yup.number, 'transformEmptyNumber', function () {
    return this.transform((val, originVal) => {
      return originVal === '' ? undefined : val;
    });
  });

  yup.addMethod(yup.string, 'isUrl', function () {
    return this.test({
      name: 'isUrl',
      message: (prm) => `${labelText(prm)} 格式有誤。`,
      test: checkUrl,
    });
  });

  yup.addMethod(yup.string, 'onlyZhEnNumber', function () {
    return this.test({
      name: 'onlyZhEnNumber',
      message: (prm) => `${labelText(prm)} 只能輸入中文/標點符號/英數字。`,
      test: checkZhEnNumber,
    });
  });

  yup.addMethod(yup.string, 'noEmoji', function () {
    return this.test({
      name: 'noEmoji',
      message: (prm) => `${labelText(prm)}不可有特殊符號。`,
      test: checkNoEmoji,
    });
  });

  // yup.addMethod(yup.string, 'isPassSanitize', function () {
  //   return this.test({
  //     name: 'isPassSanitize',
  //     message: (prm) => `${labelText(prm)} 含不可使用的HTML元件。`,
  //     test: (val) => {
  //       return val.indexOf('<script>') < 0;
  //     },
  //   });
  // });

  yup.addMethod(yup.string, 'isColor', function () {
    return this.test({
      name: 'isColor',
      message: '顏色格式錯誤，範例：#FF0000',
      test: checkColor,
    });
  });

  yup.addMethod(yup.string, 'isProductSnGuidOrIsbn', function () {
    return this.test({
      name: 'isProductSnGuidOrIsbn',
      message: `商品26碼/ISBN 格式錯誤`,
      test: (value) => isEmptyOrEmptyStringAfterTrim(value) || checkProductSnGuidOrIsbn(value),
    });
  });

  yup.addMethod(yup.string, 'isMultiProductSnGuidOrIsbn', function () {
    return this.test({
      name: 'isProductSnGuidOrIsbn',
      message: `商品26碼/ISBN 格式錯誤`,
      test: (value) => isEmptyOrEmptyStringAfterTrim(value) || checkMultiProductSnGuidOrIsbn(value),
    });
  });
}
