<script setup>
import { computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { usePageStatusStore } from '@/stores/page-status';
import { useSidebar } from '@/composables/layout/use-sidebar';
import HomeHeader from '@/components/layout/HomeHeader.vue';
import HomeSidebar from '@/components/layout/HomeSidebar.vue';
import AllWarningToast from '@/components/components-with-inject/modal/AllWarningToast.vue';
import LoadingModal from '@/components/ui/modal/LoadingModal.vue';
import WarningSystemError from '@/components/ui/WarningSystemError.vue';
import Warning400Error from '@/components/ui/Warning400Error.vue';
import { layoutElementIds } from '@/constants/layout';

const route = useRoute();
const { getIsSidebarCollapsed, getIsTemporaryExpandSidebar, handleToggleSidebar, handleTurnOnTemporaryExpandSidebar, handleTurnOffTemporaryExpandSidebar } = useSidebar();
const pageStatusStore = usePageStatusStore();
const { getPageLoading, getPageError500, getPageError400 } = storeToRefs(pageStatusStore);
const { setPageNormal } = pageStatusStore;

function close500Error() {
  setPageNormal();
}

const mainContentClassByPath = {
  default: 'tw-w-full tw-min-w-[1280px]',
  b008: 'tw-w-fit tw-min-w-[1304px]', // 因 b008 桌機版圖寬 1280，設tw-w-fit tw-min-w-[1304px]。ToDo: snapshot 截圖不完整
};

const isEditB008 = computed(() => route.path === '/home-ad/edit-slot/b008');
</script>

<script>
export default {
  name: 'DefaultLayout',
};
</script>

<template>
  <div class="tw-max-w-[1788px]">
    <WarningSystemError v-if="getPageError500" @close="close500Error"></WarningSystemError>
    <Warning400Error v-if="getPageError400"></Warning400Error>
    <div class="tw-overflow-hidden">
      <home-header :is-sidebar-collapsed="getIsSidebarCollapsed" @toggle-sidebar="handleToggleSidebar"></home-header>
    </div>
    <div class="tw-flex tw-h-[calc(100vh-60px)]" data-testid="layout-fixed-height-wrapper">
      <div class="tw-flex-none tw-h-full tw-overflow-auto tw-border-neutral-100 tw-border-r tw-border-solid" data-testid="home-sidebar">
        <home-sidebar
          :is-sidebar-collapsed="getIsSidebarCollapsed"
          :is-temporary-expand-sidebar="getIsTemporaryExpandSidebar"
          class="tw-flex-none"
          @turn-on-temporary-expand-sidebar="handleTurnOnTemporaryExpandSidebar"
          @turn-off-temporary-expand-sidebar="handleTurnOffTemporaryExpandSidebar"
        ></home-sidebar>
      </div>
      <div class="tw-relative tw-flex-1 tw-w-full tw-h-full tw-overflow-x-hidden">
        <div :id="layoutElementIds.mainContent" class="tw-overflow-auto tw-w-full tw-h-full">
          <div class="tw-max-w-[calc(1788px-60px)]" :class="isEditB008 ? mainContentClassByPath.b008 : mainContentClassByPath.default">
            <router-view :key="$route.path" />
          </div>
        </div>
        <AllWarningToast></AllWarningToast>
        <LoadingModal v-if="getPageLoading"></LoadingModal>
        <div :id="layoutElementIds.mainContentAbsolute" class="ec-z-footer tw-absolute tw-inset-x-0 tw-bottom-0"></div>
      </div>
    </div>
  </div>
</template>
