<script setup>
import ModalSimple from '@/components/ui/modal/ModalSimple.vue';
import ModalBodyTitleWithIcon from '@/components/ui/modal/ModalBodyTitleWithIcon.vue';

const props = defineProps({
  title: {
    type: String,
    default: '系統錯誤',
  },
  subtitle: {
    type: String,
    default: '',
  },
});

const emit = defineEmits(['close']);
function emitClose() {
  emit('close');
}
</script>

<template>
  <ModalSimple @close="emitClose">
    <ModalBodyTitleWithIcon :title="title" :subtitle="subtitle"></ModalBodyTitleWithIcon>
  </ModalSimple>
</template>
