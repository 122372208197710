<script setup>
import { computed } from 'vue';

const props = defineProps({
  size: {
    type: String,
    default: 'medium',
    validator: function (value) {
      return ['small', 'medium', 'large'].indexOf(value) !== -1;
    },
  },
  isSubmitButton: {
    type: Boolean,
    default: false,
  },
});

const baseClasses = 'ec-btn';

const classesBySize = {
  small: 'ec-btn-sm',
  medium: 'ec-btn-base',
  large: 'ec-btn-lg',
};

const classes = computed(() => {
  return `${baseClasses} ${classesBySize[props.size]}`;
});
</script>

<script>
export default {
  name: 'PrimaryButton',
};
</script>

<template>
  <button :type="isSubmitButton ? 'submit' : 'button'" :class="classes">
    <slot></slot>
  </button>
</template>
