import { ref, computed, shallowRef } from 'vue';
import { defineStore } from 'pinia';
import { pageStatusEnum } from '@/constants/page-status';

export const usePageStatusStore = defineStore('pageStatus', () => {
  const pageStatus = ref(pageStatusEnum.normal);
  const error400Title = ref('');
  const error400Subtitle = ref('');
  const previous400Callback = shallowRef(null);
  const pageNormalCallback = shallowRef(null);

  const getPageLoading = computed(() => pageStatus.value === pageStatusEnum.loading);
  const getPageError500 = computed(() => pageStatus.value === pageStatusEnum.error500);
  const getPageError400 = computed(() => pageStatus.value === pageStatusEnum.error400);
  const getError400Title = computed(() => error400Title.value);
  const getError400Subtitle = computed(() => error400Subtitle.value);

  function clear400Callback() {
    if (!previous400Callback.value) return;
    previous400Callback.value();
    previous400Callback.value = null;
  }

  function clearPageNormalCallback(isNewStatusPageNormal = false) {
    if (pageNormalCallback.value) {
      if (isNewStatusPageNormal) {
        pageNormalCallback.value();
      }
      pageNormalCallback.value = null;
    }
  }

  function setPageLoading() {
    clear400Callback();
    pageStatus.value = pageStatusEnum.loading;
  }

  function setPageError400({ callback = null, title = '', subtitle = '' }) {
    clear400Callback();
    clearPageNormalCallback();
    pageStatus.value = pageStatusEnum.error400;
    error400Title.value = title;
    error400Subtitle.value = subtitle;
    if (callback) previous400Callback.value = callback;
  }
  function setPageError500() {
    pageStatus.value = pageStatusEnum.error500;
    clearPageNormalCallback();
  }
  function setPageNormal() {
    pageStatus.value = pageStatusEnum.normal;
    clearPageNormalCallback(true);
  }
  function setCallbackForPageNormal(callback) {
    pageNormalCallback.value = callback;
  }

  return {
    getPageLoading,
    getPageError400,
    getPageError500,
    getError400Title,
    getError400Subtitle,
    setPageLoading,
    setPageError500,
    setPageError400,
    setPageNormal,
    setCallbackForPageNormal,
    clear400Callback,
  };
});
