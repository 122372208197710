/**
 * check image exist
 * @param url
 */

export default ({ app }) => {
  // registry Directive
  app.directive('default-image', async function (element, binding) {
    element.addEventListener('error', () => {
      element.setAttribute('src', binding.value || '/images/product-default.webp');
    });
  });
};
