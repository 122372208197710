export const langText = {
  placeholder: {
    color: '請填入色碼，例如 #FF0000',
    link: '請輸入連結 https://',
    productId: '請輸入 26 碼 / ISBN',
    productIdList: '請統一加入26碼或統一加入ISBN；請用Enter斷行隔開',
    productName: '請輸入商品名稱',
  },
  editSlotInfoTextLimit: '前台版位顯示為固定高，如字數設定過多將影響版位顯示，請斟酌命名',
};

export const validatorLangText = {
  adImageFileType: ({ label = '圖片', imageTypes } = {}) => `${label}格式僅限 ${imageTypes.join('、')}。`,
};
