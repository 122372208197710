<script setup>
const props = defineProps({
  text: {
    type: String,
    default: '系統錯誤',
  },
});

const emit = defineEmits(['close']);
const emitClose = () => emit('close');
</script>

<template>
  <div
    class="tw-rounded tw-pl-4 tw-w-[27.5rem] tw-border tw-border-solid tw-border-system-eslite-red-200 tw-bg-system-red-50 tw-flex tw-items-center tw-shadow-[0px_1px_4px_0px_rgba(0,0,0,0.10)]"
    data-testid="warning-toast"
  >
    <img src="/icons/icon-warning-triangle.svg" class="tw-mr-2" />
    <span>{{ text }}</span>
    <div class="tw-ml-auto tw-px-4 tw-py-4 tw-cursor-pointer" @click="emitClose">
      <img src="/icons/icon-close-red.svg" />
    </div>
  </div>
</template>
