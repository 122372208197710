<script setup>
import { debounce } from 'lodash-es/function';
import { computed, inject } from 'vue';
import ButtonPrimary from '@/components/ui/button/ButtonPrimary.vue';

const props = defineProps({
  isSidebarCollapsed: {
    type: Boolean,
    default: false,
  },
});

const sidebarIcon = computed(() => {
  if (props.isSidebarCollapsed) {
    return '/icons/icon-bars.svg';
  }
  return '/icons/icon-bars-shrink.svg';
});

const emit = defineEmits(['toggleSidebar']);

const auths = inject('$auths');

const userName = computed(() => auths.getterUserInfo.value?.name || '');
const userDisplayName = computed(() => auths.getterUserInfo.value?.displayName || '');

const emitToggleSidebar = debounce(
  () => {
    emit('toggleSidebar');
  },
  500,
  { leading: true },
);

function logout() {
  auths.logout();
}
</script>

<template>
  <div class="tw-h-[60px]" data-testid="home-header">
    <div class="tw-h-[60px] tw-fixed tw-w-screen tw-bg-white tw-flex tw-border-b tw-border-solid tw-border-neutral-100">
      <button
        class="tw-flex-none tw-h-full tw-w-[68px] tw-inline-flex tw-justify-center tw-items-center tw-bg-white tw-border-0 tw-border-r tw-border-neutral-100"
        data-testid="header-sidebar-button"
        @click="emitToggleSidebar"
      >
        <img :data-testid="isSidebarCollapsed && 'is-collapsed'" :src="sidebarIcon" class="tw-w-5" />
      </button>
      <router-link class="tw-flex-none tw-mr-auto tw-inline-block tw-h-full tw-px-6 tw-pt-4" to="/">
        <img src="/header/Logo-eslite.svg" class="tw-h-7" />
        <img src="/logo-subtract.svg" class="tw-mx-3 tw-mb-[6px] tw-h-[0.625rem]" />
        <img src="/header/Logo-eslite-CMS.svg" class="tw-h-6" alt="誠品電商管理後台" />
      </router-link>
      <div class="tw-ml-9 tw-flex-1 tw-basis-12 tw-shrink-0 tw-self-center tw-overflow-hidden [&>*]:tw-text-right [&>*]:tw-max-w-full">
        <div class="tw-text-sm tw-font-medium tw-text-neutral-500 tw-text-ellipsis tw-overflow-hidden tw-text-nowrap">{{ userDisplayName }}</div>
        <div class="tw-text-xs tw-text-neutral-400">{{ userName }}</div>
      </div>
      <ButtonPrimary class="tw-ml-3 tw-mr-8 tw-self-center tw-flex-none tw-flex tw-text-base ec-btn-outline-muted" @click="logout">
        <img src="/icons/icon-exit.svg" class="tw-mr-2" />
        <span>登出</span>
      </ButtonPrimary>
    </div>
  </div>
</template>
